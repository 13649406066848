import React from "react";
import { Col,Text } from "../../style"

interface SubInfoProps {
    name:string;
    price:string;
    days:string;
}

export const SubInfo:React.FC<any> = ({options})=>{
    
    return (
        <>
        <Col>
            <Text >By placing your monthly recurring order of {options.name}- you will be charged {options.price} Now and every {options.day} days thereafter until you cancel your subscription. You will receive an electronic notification 5 to 7 days prior to your transaction and receipt after each successful transaction.</Text>
        </Col>
        </>
    )
}