import styled from "styled-components"
import { Icons } from "../../constants/images"
import { useCampaign } from "../../context/CampaignContext"
import { useDeviceType } from "../../context/DeviceContext"
import { Col, Row, Text, Title, StyledHR, Img } from "../../style"
import { useCart } from "../../context/CartContext"
import { Colors } from "../../theme/colors"
import { usePopup } from "../../context/PopUpContext"
import { TextLink } from "../generic/TextLink"

interface CartItemProps{
    cartItem:CartItem
}
export const CartItem = ({cartItem}:CartItemProps)=>{
    const { isMobile } = useDeviceType()
    const {campaign} = useCampaign()
    const {removeFromCart,removeOneFromCart,addToCart,cart,coupon} = useCart()
    const itemIndex = cart.findIndex(item=>item.campaignProductId===cartItem.campaignProductId)
    const product = campaign?.products.find(pr=>pr.campaignProductId===cartItem.campaignProductId)
    const couponDiscount = coupon?.discountPerc?1-coupon.discountPerc:1
    const {openPopup} = usePopup()

    const clickSubscription = ()=>{
        if(product?.billingCycleType==='RECURRING'){
            const options = {
                name:product?.productName,
                price:(campaign?.currencySymbol || "$") + (product?.price?(product?.price * cartItem.qty).toFixed(2):"0.00"),
                day:"30"
                
            }
            openPopup('/sub-info',options)
        }

    }

    return (
        <> 

         <Row padding="10px 0px">
         
                    <Col $maxWidth="60%" align="start">
                        <Row>
                            <Col $maxWidth="10%">
                                <RemoveBtn src={Icons.CartRemove} width={18} onClick={()=>removeFromCart(itemIndex)}/>
                            </Col>
                            <Col $maxWidth="25%">
                                <ItemImg src={product?.imageUrl}/>
                            </Col>

                            <Col align="center">
                                <Title dir="start" $isMobile={isMobile} $fontSize={18} $lineHeight={22} weight={700}>{product?.productName}</Title>
                                <Text  margin="0" direction="start" $fontSize="16px">{product?.productQty} bottles</Text>
                                {product?.billingCycleType==='RECURRING' &&
                                    <Text $fontSize="14px" direction="center"> Monthly Refill - <TextLink onClick={clickSubscription}>{isMobile?"Terms":"Read Terms"}</TextLink></Text>
                                   
                                  
                                    // <Link color={Colors.primary} onClick={clickSubscription} margin="0" direction="start" $fontSize="16px">subscription*</Link>
                                }
                                
                            </Col>



                        </Row>

                    </Col>
                    <Col $maxWidth="20%" align="center">
                        <Row>
                            <Col $maxWidth="15%">
                                <BtnAddRem dir="start" $isMobile={isMobile} $fontSize={18} $lineHeight={22} weight={700} onClick={()=>removeOneFromCart(cartItem.campaignProductId)}>-</BtnAddRem>
                            </Col>
                            <Col $maxWidth="40%">
                                <Title dir="start" $isMobile={isMobile} $fontSize={18} $lineHeight={22} weight={700}>{cartItem.qty}</Title>
                            </Col>
                            <Col $maxWidth="15%">
                                <BtnAddRem dir="start" $isMobile={isMobile} $fontSize={18} $lineHeight={22} weight={700} onClick={()=>addToCart(cartItem.campaignProductId)}>+</BtnAddRem>
                            </Col>
                        </Row>
                    </Col>
                    <Col $maxWidth="20%" align="center">
                    { couponDiscount &&
                        <Title decoration="line-through" $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={500}>{campaign?.currencySymbol}{product?.price?(product?.price * cartItem.qty).toFixed(2):0}</Title>
                    }
                        
                        <Title $isMobile={isMobile} $fontSize={20} $lineHeight={26} weight={500}>{campaign?.currencySymbol}{product?.price?(product?.price* couponDiscount * cartItem.qty).toFixed(2):0}</Title>
                    </Col>
                
                </Row>
             
                <StyledHR $height={1}/>
               
        </>
        
    )

}

const ItemImg = styled.img`
height: 55px;
`

const RemoveBtn = styled.img<{margin?:string}>`
  height: auto;
  margin:${props => props.margin || '0px'};
  cursor: pointer;
`;

const InfoBox = styled.div`
position: absolute;
display: flex;
background: red;
top:0;
width: 400px;
height: auto;
cursor: pointer;
z-index: 50000;
`;

const BtnAddRem = styled.h3<{$isMobile:boolean, $fontSize:number, color?:string , weight?:number, $lineHeight?:number}>`
font-size: ${props=>props.$fontSize}px;
line-height: ${props=>props.$lineHeight}px;

color: ${props=>props.color || Colors.blackText};
margin:0;
font-weight: ${props=>props.weight || 700};
padding: 0;
cursor: pointer;
/* margin-top: -20px; */
`

