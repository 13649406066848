import React from 'react';
import styled from 'styled-components';
import { useDeviceType } from '../../context/DeviceContext';
import { TermsEu } from '../legal/eu/terms';
import { TermsUs } from '../legal/us/terms';
import { PrivacyEu } from '../legal/eu/privacy';
import { PrivacyUs } from '../legal/us/privacy';
import { SubInfo } from '../cart/subInfo';

interface PopupProps {
    isOpen: boolean;
    contentUrl: string;
    onClose: () => void;
    options?:any
}

// Create a map of content components
const contentMap: { [key: string]: React.FC<any> } = {
    '/eu/terms': TermsEu,
    '/us/terms': TermsUs,
    '/eu/privacy': PrivacyEu,
    '/us/privacy': PrivacyUs,
    '/sub-info': SubInfo,
};

const Popup: React.FC<PopupProps> = ({ isOpen, contentUrl, onClose,options }) => {
    const { isMobile } = useDeviceType();

    if (!isOpen) {
        return null;
    }

    // Get the appropriate content component
    const ContentComponent = contentMap[contentUrl];
    return (
        <Overlay onClick={onClose}>
            <Content $isMobile={isMobile} onClick={e => e.stopPropagation()}>
                <CloseButton onClick={onClose}>×</CloseButton>
                <ContentArea>
                    {ContentComponent ? <ContentComponent options={options} /> : <div>Content not found</div>}
                </ContentArea>
            </Content>
        </Overlay>
    );
};

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    box-sizing: content-box;
`;

const Content = styled.div<{$isMobile:boolean}>`
    background: #fff;
    box-sizing: content-box;
    padding: 20px;
    border-radius: ${props=>props.$isMobile?"0":"8px"};
    max-width: 600px;
    width: 100%;
    
    height: ${props=>props.$isMobile?"95%":"80%"};
    position: relative;
    overflow: hidden;
`;

const ContentArea = styled.div`
    overflow-y: auto;
    height: 100%;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    height: auto;
    background: transparent;
    border: none;
    font-size: 24px;
    z-index: 5;
    cursor: pointer;
`;

export default Popup;
