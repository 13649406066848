import React, { createContext, useContext, useEffect, useState } from 'react';

interface CartContextType {
  cart: CartItem[];
  coupon:CampaignCupons | null;
  setCoupon: React.Dispatch<React.SetStateAction<CampaignCupons | null>>;
  setCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
  addToCart: (productId: number) => void;
  removeOneFromCart: (productId: number) => void;
  emptyCart: () => void;
  removeFromCart: (index: number) => void;
  updateCart: (item: CartItem, index: number) => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

interface CartProviderProps {
  children: React.ReactNode;
}

export const CartProvider = ({ children }: CartProviderProps) => {
  const [cart, setCart] = useState<CartItem[]>(() => {
    // Retrieve the cart from localStorage if it exists
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });

  const [coupon, setCoupon] = useState<CampaignCupons | null>(() => {
    // Retrieve the cart from localStorage if it exists
    const storedCoupon = localStorage.getItem('coupon');
    return storedCoupon ? JSON.parse(storedCoupon) : [];
  });

  useEffect(() => {
    // Save the cart to localStorage whenever it changes
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    // Save the cart to localStorage whenever it changes
    localStorage.setItem('coupon', JSON.stringify(coupon));
  }, [coupon]);

  const addToCart = (productId: number) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (item) => item.campaignProductId === productId
      );
  
      if (existingItemIndex !== -1) {
        // If the item already exists, increase its qty
        return prevCart.map((item, index) =>
          index === existingItemIndex
            ? { ...item, qty: item.qty + 1 }
            : item
        );
      } else {
        // If the item doesn't exist, add it to the cart
        return [...prevCart, { campaignProductId: productId, qty: 1 }];
      }
    });

  };

  const removeOneFromCart = (productId: number) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (item) => item.campaignProductId === productId
      );
  
      if (existingItemIndex !== -1) {
        // If the item already exists, increase its qty
        return prevCart.map((item, index) =>
          index === existingItemIndex
            ? { ...item, qty: item.qty>1?item.qty - 1:item.qty }
            : item
        );
      } else {
        // If the item doesn't exist, add it to the cart
        return [...prevCart, { campaignProductId: productId, qty: 1 }];
      }
    });
    
  };

  const emptyCart = () => {
    setCart([]);
    
  };

  const removeFromCart = (index: number) => {
    setCart((prevCart) => prevCart.filter((_, i) => i !== index));
  };

  const updateCart = (item: CartItem, index: number) => {
    setCart((prevCart) =>
      prevCart.map((cartItem, i) => (i === index ? item : cartItem))
    );
  };

  // The value that will be provided to any descendant components
  const value: CartContextType = { cart, setCart, addToCart, removeFromCart, updateCart,removeOneFromCart,emptyCart,coupon,setCoupon };

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
};